import * as React from "react"
import Layout from "../components/layout"

function IndexPage({ pageContext }) {
  const { p } = pageContext
  return (
    <Layout>
    <div>
    Pokemon: {p}
    </div>
    </Layout>
  )
}

export default IndexPage
